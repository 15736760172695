/**
  * Overriding styles for react-date-range picker component, for dark mode.
  * If this file needs to be updated, consult the original styles at
  *   /frontend/app/node_modules/react-date-range/dist/theme/default.css
  * and update these styles accordingly.
  */

.dateRangePicker {
  /* copied from /frontend/app/src/utils/style.ts */
  --DOPPEL_TEXT_BLACK: #000000;
  --DOPPEL_TEXT_DARK: #181921;
  --DOPPEL_CYAN_200: #77FFFD;

  /* variables for this file */
  --PRIMARY_TEXT: var(--DOPPEL_TEXT_DARK);
  --HIGHLIGHT: var(--DOPPEL_CYAN_200);
}

.dateRangePicker :global(.rdrDateDisplayItemActive) {
  border-color: var(--HIGHLIGHT);
}

.dateRangePicker :global(.rdrDateDisplayItem input) {
  color: var(--PRIMARY_TEXT);
}

/* Today */
.dateRangePicker :global(.rdrDayToday .rdrDayNumber span) {
  font-weight: 500;
}

.dateRangePicker :global(.rdrDayToday .rdrDayNumber span:after) {
  background-color: var(--PRIMARY_TEXT) !important;
}

/* Hover */
.dateRangePicker :global(.rdrDayHovered .rdrDayNumber:after) {
  border: 1px solid var(--HIGHLIGHT);
}

/* Selected, In Range, Edges */
.dateRangePicker :global(.rdrSelected),
.dateRangePicker :global(.rdrInRange),
.dateRangePicker :global(.rdrStartEdge),
.dateRangePicker :global(.rdrEndEdge) {
  background: var(--HIGHLIGHT);
}

.dateRangePicker :global(.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span),
.dateRangePicker :global(.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span),
.dateRangePicker :global(.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span),
.dateRangePicker :global(.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span) {
  color: var(--PRIMARY_TEXT);
}